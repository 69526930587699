import { ApiRoutes } from "@/lib/routes";

import BrowseUsersStore from "@/areas/settings/stores/BrowseUsersStore";
import BrowseRolesStore from "@/areas/settings/stores/BrowseRolesStore";
import BrowseRiskTypesStore from "@/areas/settings/stores/BrowseRiskTypesStore";
import CreateRiskTypeStore from "@/areas/settings/stores/CreateRiskTypeStore";
import BrowseNotificationStore from "@/areas/settings/stores/BrowseNotificationStore";
import CreateTrustedDomainStore from "@/areas/directory/stores/CreateTrustedDomainStore";
import BrowseCustomFieldsStore from "@/areas/settings/stores/BrowseCustomFieldsStore";
import BrowseRegistriesStore from "@/areas/settings/stores/BrowseRegistriesStore";
import CreateRegistryStore from "@/areas/settings/stores/CreateRegistryStore";
import BrowseTargetRolesStore from "@/areas/subscriptions/stores/BrowseTargetRolesStore";

import BrowseModelsStore from "@/areas/settings/stores/BrowseModelsStore";
import BrowseEntitiesStore from "@/areas/settings/stores/BrowseEntitiesStore";
import CreateModelStore from "@/areas/settings/stores/CreateModelStore";
import CreateEntityStore from "@/areas/settings/stores/CreateEntityStore";

export default class SettingsRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseUsersStore = new BrowseUsersStore(this);
    this.browseRolesStore = new BrowseRolesStore(this);
    this.browseNotificationStore = new BrowseNotificationStore(this);
    this.browseRiskTypesStore = new BrowseRiskTypesStore(this);
    this.createRiskTypeStore = new CreateRiskTypeStore(this);
    this.createTrustedDomainStore = new CreateTrustedDomainStore(this);
    this.browseCustomFieldsStore = new BrowseCustomFieldsStore(this);
    this.browseRegistriesStore = new BrowseRegistriesStore(this);
    this.createRegistryStore = new CreateRegistryStore(this);
    this.browseModelsStore = new BrowseModelsStore(this);
    this.browseEntitiesStore = new BrowseEntitiesStore(this);
    this.createModelStore = new CreateModelStore(this);
    this.createEntityStore = new CreateEntityStore(this);
  }
}
